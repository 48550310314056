import colors from 'src/styling/colors'
import vars from 'src/styling/vars'
import media from 'src/styling/media'
import { isCordova } from 'src/env'
import OpenSansRegular from '../assets/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../assets/fonts/OpenSans-Bold.ttf'
import { breakpoints } from 'src/styling/media'

export default `
  @font-face {
    font-family: "Open Sans";
    src: url(${OpenSansRegular}) format('truetype');
    font-weight: regular;
    font-display: block;
  }

  @font-face {
    font-family: "Open Sans";
    src: url(${OpenSansBold}) format('truetype');
    font-weight: bold;
    font-display: block;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote,
  a, abbr, acronym, address, big, cite,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, sub, sup, tt, var,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  b, strong {
    font-weight: bold;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    height: 100%;
    height: calc(100% + constant(safe-area-inset-top) + constant(safe-area-inset-bottom));
    height: calc(100% + env(safe-area-inset-top) + env(safe-area-inset-bottom));
    touch-action: manipulation;
  }

  body {
    font-family: ${vars.fontFamily};
    color: ${colors.textColor};
    height: 100%;
    overscroll-behavior-y: none;
    padding-top: constant(safe-area-inset-top) !important; // important required so screen doesn't jump when modal opens on mobile
    padding-top: env(safe-area-inset-top) !important; // important required so screen doesn't jump when modal opens on mobile
  }

  #root {
    // we're using @supports to target all browsers than aren't IE11
    // the 'color: red' bit doesn't matter at all
    // all that matters is that IE11 doesn't support @support
    @supports (color: red) {
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }
    max-width: 100vw;
  }

  ol, ul {
    list-style: none;
  }

  a {
    color: currentColor;
  }

  img, svg {
    vertical-align: bottom;
  }

  select {
    background-color: transparent;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
  }
  ::-ms-clear, ::-ms-reveal { display: none; }

  ${isCordova &&
    `
  :focus {
    outline: none;
  }
  `}
`
