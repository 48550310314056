import React, { useMemo, useEffect, Fragment, useCallback } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import _ from 'lodash'
import { Switch } from 'src/components/RouterDom'
import { renderRoutes } from 'react-router-config'
import { useDispatch, useSelector } from 'react-redux'
import { SkipNavLink, SkipNavContent } from 'src/components/SkipNav'
import { LiveAnnouncer, LiveMessage } from 'react-aria-live'
import { useCentre, useCrisis } from 'src/store/resources/hooks'
import {
  fetchResource,
  fetchResources
} from 'src/store/resources/actionCreators'
import { usePageTitle, usePageMeta } from 'src/store/ui/hooks'
import SearchModal from 'src/components/Modal/SearchModal'
import Page, { getPageData } from 'src/components/Page'
import { restore } from 'src/store/account/actionCreators'
import { getCentreId } from 'src/config'
import CookiePolicyWidgetLarge from './CookiePolicyWidgetLarge'
import createTheme from 'src/styling/createTheme'
import uiSelector from 'src/store/ui/selector'
import uiConstants from 'src/store/ui/constants'
import vars from 'src/styling/vars'
import env, { isBrowser, isCordova } from 'src/env'
import { hideOnboardingScreen } from 'src/store/ui/actionCreators'
import Onboarding from 'src/pages/Root/Header/Mobile/Onboarding'
import InstagramEmbed from 'src/components/InstagramEmbed'
import CritizrEmbed from 'src/components/CritizrEmbed'

import Header from './Header'
import CentresSitesDirectory from './CentresSitesDirectory'
import Footer from './Footer'
import AppTabBar from './AppTabBar'
import Helmet from './Helmet'
import useGTM from '../../hooks/useGTM'
import { setPageMeta } from 'src/store/ui/actionCreators'
import { customMoment } from 'src/utility'
import { useInternationalisation } from 'src/context'

const Main = styled.main`
  flex: 1 1 auto;
  z-index: ${vars.zIndex.main};
  display: flex;
  flex-direction: column;
`

const Root = (props) => {
  const { route, location } = props
  const pathname = _.get(location, 'pathname')
  const dispatch = useDispatch()
  const title = usePageTitle()
  const meta = usePageMeta() || {}
  const noTitle = _.isNil(title)
  const { translateUrl } = useInternationalisation()
  const centre = useCentre()
  const crisis = useCrisis()
  const hasCenter = (getCentreId() || (centre && centre._status.succeeded))

  useGTM()

  useEffect(() => {
    dispatch(setPageMeta(meta))
    if (isBrowser) window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    dispatch(restore()).catch(() => { })
  }, [])

  const isShopOnlineRoute = pathname === '/esi-header' || pathname === '/eshop' || pathname === '/shoponline'

  useEffect(() => {
    if (hasCenter && isShopOnlineRoute) {
      getPageData({ ...props, dispatch }, { urlName: 'shoponline' })
    }
  }, [])

  const theme = useMemo(() => {
    if (centre && centre._status.succeeded) {
      return createTheme({ centre })
    } else {
      return {}
    }
  }, [centre])

  const headerEl = <Header centre={centre} crisis={crisis} />
  const footerEl = <Footer centre={centre} />

  const showOnboarding = useSelector(uiSelector(uiConstants.SHOW_ONBOARDING))

  const hideOnboarding = useCallback((route) => {
    dispatch(hideOnboardingScreen())
  })

  const { critizrApiKey } = env

  if (hasCenter) {
    // if (isShopOnlineRoute) {
    //   return (
    //     <React.Fragment>
    //       {headerEl}
    //       <Page box inner requestKey={'shoponline'} />
    //     </React.Fragment>
    //   )
    // }
    if (pathname === '/esi-footer') {
      return footerEl
    }
    if (pathname === '/esi-cookie') {
      return (<>
        <CookiePolicyWidgetLarge />
      </>)
    }
    if (_.startsWith(pathname, '/shoponline') || _.startsWith(pathname, '/eshop')) {
      if (isBrowser && !isCordova) {
        // reload so our reverse proxy takes effect
        window.location.reload()
      }
      return null
    }

    return (
      <LiveAnnouncer>
        {!noTitle ? (
          <LiveMessage message={title || ''} aria-live='polite' />
        ) : null}
        <ThemeProvider theme={theme}>
          <Fragment>
            <Helmet />
            <InstagramEmbed />
            {!isCordova && <SkipNavLink />}
            {headerEl}
            {!isCordova && <SkipNavContent />}
            <Main>
              <Switch>{renderRoutes(_.get(route, 'routes'), centre)}</Switch>
            </Main>
            {isCordova ? <AppTabBar /> : footerEl}
            <SearchModal {...props} />
            <CookiePolicyWidgetLarge />
            {showOnboarding && isCordova ? (
              <Onboarding
                onClose={(route) => {
                  hideOnboarding()
                  if (route) {
                    props.history.push(translateUrl(route))
                  }
                }}
              />
            ) : null}
            {critizrApiKey && <CritizrEmbed critizrApiKey={critizrApiKey} />}
          </Fragment>
        </ThemeProvider>
      </LiveAnnouncer>
    )
  } else {
    if (isBrowser && env.envKey !== 'prod') {
      return <CentresSitesDirectory />
    } else {
      return null
    }
  }
}

Root.getData = async (props) => {
  const { host, dispatch, locale } = props
  const centreId = getCentreId({ host })
  if (centreId) {
    await dispatch(
      fetchResource({
        id: centreId,
        resourceType: 'centre',
        requestKey: 'centre',
        locale
      })
    )

    const now = new customMoment({ host })().toISOString()
    await dispatch(
      fetchResources({
        locale,
        resourceType: 'retailUnitCategory',
        requestKey: 'retailUnitCategory',
        host
      })
    )

    await dispatch(
      fetchResources({
        locale,
        resourceType: 'layout',
        requestKey: 'layout',
        host,
        include: 3
      })
    )

    await dispatch(
      fetchResources({
        locale,
        resourceType: 'promotion',
        requestKey: 'promotion',
        centreId,
        order: 'fields.stickyOrdering,-fields.displayFrom',
        where: {
          [`fields.${isCordova ? 'publishOnPlusApp' : 'publishOnWebsite'
            }`]: true,
          'fields.displayFrom[lte]': now,
          ...(!isCordova && { 'fields.categoryType[ne]': 'Reward' })
        }
      })
    )

    await dispatch(
      fetchResources({
        locale,
        resourceType: 'crisis',
        requestKey: 'crisis',
        centreId,
        where: {
          'fields.startDateTime[lte]': now,
          'fields.endDateTime[gte]': now
        },
        order: 'sys.createdAt',
        limit: 1
      })
    )
  } else {
    throw new Error('no centre id')
  }
}
Root.identifier = 'ROOT'

export default Root